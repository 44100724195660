import React from 'react';

import {
  getValueType,
  renderValue,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-cell/goals-matrix-cell';

import Icons from 'assets/icons';

const GoalItemContent = ({ goal, renderDetails, renderGoalValue }) => (
  <div className="flex flex-col justify-center items-center text-gray-03">
    <div className="font-medium mb-2 text-center">
      {goal.relativeToLastYear} {renderDetails()} to
    </div>
    {renderGoalValue()}
  </div>
);

const GoalItem = ({ goal }) => {
  const valueType = getValueType(goal);

  const renderDetails = () =>
    goal.details.map((detail, detailIndex) => (
      <React.Fragment key={detail.id}>
        {detail.focusArea}
        {detailIndex !== goal.details.length - 1 && (
          <Icons.PlusInCircle
            className="h-4 w-4 text-gray-04 inline-block mx-1"
            aria-hidden="true"
          />
        )}
      </React.Fragment>
    ));

  const renderGoalValue = () => (
    <p className="text-3xl font-bold flex items-center">
      {renderValue({
        value: goal.value,
        valueType,
        goalType: goal.type,
      })}
    </p>
  );

  return (
    <div className="bg-gray-00 p-5 rounded-md w-72 h-40 flex flex-col justify-center border border-navy-01">
      {goal.category === 'Other' ? (
        <div className="flex flex-col justify-center items-center">
          <div className="font-medium text-center">{goal.name}</div>
        </div>
      ) : (
        <GoalItemContent
          goal={goal}
          renderDetails={renderDetails}
          renderGoalValue={renderGoalValue}
        />
      )}
    </div>
  );
};

export default GoalItem;
