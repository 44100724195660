import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Icons from 'assets/icons';

const NavButton = ({ link, text, customClass }) => {
  const isExternal = /^https?:\/\//.test(link);

  const content = (
    <>
      <div className="text-sm font-semibold">{text}</div>
      <Icons.DoubleArrow customClass="h-2.5 w-2.5" />
    </>
  );

  const classes = classNames(
    'flex items-center gap-1.5 text-blue-02 hover:gap-2 hover:text-indigo-02 transition-all',
    customClass
  );

  return (
    <div className="inline-flex">
      {isExternal ? (
        <a href={link} target="_blank" className={classes}>
          {content}
        </a>
      ) : (
        <Link to={link} className={classes}>
          {content}
        </Link>
      )}
    </div>
  );
};

export default NavButton;
